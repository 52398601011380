.user{
    background-color: rgb(238, 238, 238);
    padding: 10px;
    width: auto;
    max-width: 400px;

    .edit-password{
        background-color: rgb(214, 214, 214);
        padding: 10px;
    }
    .user-active{
        cursor: pointer;
    }
}
