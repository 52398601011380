.event-history{

    .event-history-content{
        padding-bottom: 50px;
        
        .event-list-item{
            display: flex;
            justify-content: space-between;

            div{
                width: 100px;
            }
            .single-event-service-list{
                position: relative;

                ul{
                    display: none;
                    position: absolute;
                    z-index: 500;
                    right: 10px;

                    li{
                        background-color: rgb(175, 216, 255);
                        font-size: .7rem;
                        width: 200px;
                        height: 35px;
                        padding: 10px;
                        z-index: 500;
                        border: none;
                    }
                }
            }
            .single-event-service-list:hover ul{
                display: block;
            }
        }
        .search{
            max-width: 300px;
        }
        .dropdown-list-wrapper{
            display: flex;
            margin: 10px 0;

            .btn-outline-primary{
                min-width: 180px;
            }
            .dropdown-list-container{
                position: relative;
    
                .dropdown-list{
                    background-color: rgb(255, 251, 251);
                    position: absolute;
                    z-index: 100;
                    list-style: none;
                    width: 200px;
                    top: 50px;
                    border-radius: 5px;
                    border: 1px solid rgb(114, 126, 255);
                    padding: 0;
                    padding: 10px;
                }

                img{
                    height: 20px;
                    margin: 10px;
                    cursor: pointer;
                }
            }
        }
        #stations-wrapper{
            justify-content: flex-start;

            .dropdown-list{
                left: 0;
            }
        }
        #services-wrapper{
            justify-content: flex-start;
            align-items: start;

            .dropdown-list{
                overflow-y: auto;
                max-height: 300px;
                width: 400px;
                left: 0;
                
                li{
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
        #atributes-wrapper{
            justify-content: flex-end;
            align-items: flex-end;
            height: 100%;

            .dropdown-list{
                right: 0;
            }
        }

        .navigation{

            img{
                height: 20px;
                cursor: pointer;
            }
        }
    }
}