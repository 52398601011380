.vehicle-inspection-card{
    padding-bottom: 50px;
    .small{
        color: rgb(132, 132, 132);
    }
    #price{
        width: 20%;
    }
    #alt-destination{
        width: 50%;
    }
    .btn{
        max-width: 200px;
        height: 50px;
    }
    .drop-area{
        height: 150px;
    }
    .mapped-file{

        img{
            cursor: pointer;
            margin-left: 5px;
            height: 17px;
        }
    }
}