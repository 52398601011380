.menage-users{
 
    ul{
        list-style: none;
        padding: 0;

        li{
            cursor: pointer;
            background-color: rgb(238, 238, 238);
            margin: 20px 0;
            padding: 10px;
            width: 100%;
        }
        .search{
            background-color: rgb(175, 175, 175);
            display: flex;
            flex-direction: column;
        }
    }
}