.station{
    .search-event{
        background-color: rgb(204, 204, 204);
        width: 90%;
        min-height: 400px;
        margin: 80px auto 40px auto;

        ul{
            list-style: none;
            padding: 10px;

            li{
                font-size: 20px;
                width: 70%;
                margin: 10px auto;
                padding: 15px;
                background-color: rgb(255, 255, 255);
                display: flex;
                flex-direction: column;

                div{
                    display: flex;
                    padding: 20px;

                    button{
                        font-size: 16px;
                        height: 70px;
                        width: 100px;
                        margin: 5px;
                    }
                }
            }
        }
    }
}