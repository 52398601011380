.event{
  
    background-color: rgb(223, 223, 223);
    margin: 10px auto;
    font-size: 20px;
    padding: 25px;
    max-width: 90%;
    display: flex;
    flex-direction: column;

    button{
        font-size: 16px;
        height: 80px;
        width: 140px;
        margin: 5px;
    }
    img{
        height: 20px;
    }
    .form-control{
        max-width: 400px;
        
    }
}
