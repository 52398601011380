.manage-services{
    padding-bottom: 50px;

    .button-column{
        display: flex;
        justify-content: end;
    }
    ul{
        li{
            grid-template-columns: 20% 75% 2% 2%;
        }
    }

    .navigation{

        img{
            height: 20px;
            cursor: pointer;
        }
    }
    .edit-service{
        position: fixed;
        z-index: 100;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);

        .content{
            border-radius: 5px;
            padding: 30px;
            height: 280px;
            width: 320px;
            background-color: rgb(240, 240, 240);


        }
    }
    .edit-service-buttons{
        margin: 30px;
        display: flex;
        justify-content: space-evenly;
    }
}
