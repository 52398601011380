.ul-custom-container{
    overflow-x: auto;
    position: relative;
    height: 650px;
    margin-bottom:20px;
    display: flex;

    .custom-ul{
        display: inline-block;
        width: 100%;
        min-width: 1200px;
        padding: 0;
        margin-top: 40px;
        font-size: .7em;
        list-style: none;
        
        .first-item{
            background-color: rgb(175, 216, 255);
            cursor: auto;
            
            div{
                cursor: pointer;
            }
            img{
                height: 10px;
            }
        }
        .first-item:hover{
            background-color: rgb(175, 216, 255);
        }
        li{
            display: grid;
            cursor: pointer;
            font-size: inherit;
            border-bottom: 1px solid black;
            background-color: rgb(243, 247, 255);
            padding: 10px 0;
            margin: 0;
            height: 50px;
            transition-duration: 300ms;

            div{
                align-self: center;
            }
            img{
                height: 15px;
                transition-duration: 200ms;
            }
            img:hover{
                transform: scale(1.3);
                transition-duration: 200ms;
            }
        }
        li:hover{
            transition-duration: 300ms;
            background-color: rgb(226, 241, 255);
        }
    }
}
