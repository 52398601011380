.menage-schedules{
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;

    ul{
        width: 200px;

        li{
            cursor: pointer;
        }
    }

    div{
        background-color: rgb(196, 196, 196);
        height: 300px;
        width: 800px;
    }
}
