.client{
    ul{
        list-style: none;
    }
    .form-control{
        max-width: 400px;
    }
    .listed-vehicle{
        display: grid;
        grid-template-columns: 32% 16% 31% 21%;
        max-width: 40%;
    }
    .edit-vehicle{
        position: fixed;
        z-index: 100;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);

        .content{
            border-radius: 5px;
            padding: 30px;
            height: 370px;
            width: 320px;
            background-color: rgb(240, 240, 240);

            .buttons{
                margin: 30px;
                display: flex;
                justify-content: space-evenly;
            }
        }
    }
}