.calendar-container{
    margin-bottom: 50px;
    position: relative;
    overflow-x: auto;
    
    .rbc-calendar{
        display: inline-block;
        min-width: 800px;
        width: 100%;
    }
    .rbc-time-view{
        height: 900px;
    }
    h1{
        cursor: pointer;
    }
    .mapped-operator-active{
        background-color: rgb(214, 255, 214);
    }
    .mapped-operator-not-active{
        background-color: rgb(255, 210, 210);
    }
    .popup-wrapper{
        height: 100vh;
        width: 100vw;
        position: fixed;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 50px;
        top: 0;
        z-index: 1000;

        .new-event-popup, .new-shift-popup{
            height: auto;
            width: auto;
            padding: 40px;
            border-radius: 5px;
            background-color: rgb(240, 240, 240);
            z-index: 1000;  
        }
    }
}
